import { useState } from 'react';
import { endpoint } from '../../AppGlobal';
import CardHoldersColumns from './components/CardHolderColumns';
import CardHolderFilters from './components/CardHolderFilters';
import CollapsedSearchLayout from '../../components/CollapseSearchListView';
import { exportToExcel } from '../../const/exportToExcel';

function CardHolders() {

    const [holdersData, setHoldersData] = useState([]);

    const fetchMethod = async (page, filter) => {

        const response = await endpoint.fetchHolders(page, filter);

        const updatedResults = response.results.map(item => ({
            ...item,
            created_at: new Date(item.created_at).toLocaleDateString('en-GB'),
            updated_at: new Date(item.updated_at).toLocaleDateString('en-GB'),
            date_of_birth: new Date(item.date_of_birth).toISOString().split('T')[0]
        }));
        setHoldersData(updatedResults);
        
        return response;
    };

    return (
        <CollapsedSearchLayout fetchMethod={fetchMethod} columns={CardHoldersColumns} filters={CardHolderFilters} barRoles={[]} title={"Holders"} exportToExcel={(data) => exportToExcel(data, 'Holders.xlsx')} exportData={holdersData} />

    );
}

export default CardHolders;