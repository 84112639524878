import { useState } from 'react';
import { endpoint } from '../../AppGlobal';
import CollapsedSearchLayout from '../../components/CollapseSearchListView';
import Role from '../../const/role';
import BalanceColumns from './components/BalanceColumns';
import BalanceFilters from './components/BalanceFilters';
import ProcessorDetailsModal from '../ClientManagement/components/ProcessorDetailsModal';
import DrawerModal from '../../components/DrawerModal';
import { exportToExcel } from '../../const/exportToExcel';

function BalanceManagement() {

    
    const [balanceData, setBalanceData] = useState([]);
    
    const fetchMethod = async (page, filter) => {
        const response = await endpoint.fetchBalance(page, filter);
        
        const updatedResults = response.results.map(item => ({
            ...item,
            created_at: new Date(item.created_at).toLocaleDateString('en-GB'),
            updated_at: new Date(item.updated_at).toLocaleDateString('en-GB'),
        }));
        setBalanceData(updatedResults);
        
        return response;
    };
    
    const { modalVisible, selectedItem, openModal, closeModal } = DrawerModal();

    return (

        <div>
            <CollapsedSearchLayout
                fetchMethod={fetchMethod}
                columns={BalanceColumns(openModal)}
                filters={BalanceFilters}
                barRoles={[Role.BalanceEditor, Role.BalanceAdmin]}
                barAddURL="/balance/load"
                title={"Balance"}
                exportToExcel={(data) => exportToExcel(data, 'Balance.xlsx')} 
                exportData={balanceData}
            />
            {selectedItem && (
                <ProcessorDetailsModal
                    visible={modalVisible}
                    processor={selectedItem}
                    closeModal={closeModal}
                />
            )}
        </div>

        // <CollapsedSearchLayout 
        // fetchMethod={endpoint.getMethod('fetchBalance')} 
        // columns={BalanceColumns} 
        // filters={BalanceFilters} 
        // barRoles = {[Role.BalanceEditor, Role.BalanceAdmin]}
        // barAddURL = "/balance/load"
        // title={"Balance Management"}
        // />
    );
}

export default BalanceManagement;