import React, { useState } from 'react'
import CollapsedSearchLayout from '../../components/CollapseSearchListView'
import TransactionsColumns from './components/TransactionsColumns'
import TransactionsFilters from './components/TransactionsFilters';
import TransactionDetailsModal from './components/TransactionDetailsModal';
import { endpoint } from '../../AppGlobal';
import { useLocation } from 'react-router-dom';
import DrawerModal from '../../components/DrawerModal';
import CardDetailsModal from '../CardManagementPage/components/CardManagementDetailsModal';
import { exportToExcel } from '../../const/exportToExcel';

function Transactions() {
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    const holderId = urlParams.get('holder_id');
    const requestType = urlParams.get('request_type')
    const [transactionsData, setTransactionsData] = useState([]);

    const fetchMethod = async (page, filter) => {
        if (holderId) {
            filter = { ...filter, holder_id: holderId };
        }
        if (requestType) {
            filter = { ...filter, request_type: requestType, holder_id: holderId };
        }
        const response = await endpoint.fetchTransactions(page, filter);

        const updatedResults = response.results.map(item => ({
            ...item,
            auth_amount: item.auth_amount / 100,
            settle_amount: item.settle_amount / 100,
            trx_amount: item.trx_amount / 100,
            auth_date: item.auth_date,
            settle_date: item.settle_date,
            trx_date: item.trx_date,
            created_at: item.created_at,
        }));
        setTransactionsData(updatedResults);
        return response;
    };

    const hideSearch = !!holderId; // Condition to hide the search button if holderId exists

    const { modalVisible, selectedItem, openModal, closeModal } = DrawerModal();

    const [selectedCard, setSelectedCard] = useState(null);
    const [cardModalVisible, setCardModalVisible] = useState(false);

    const openCardModal = (card_id, processor_type) => {
        setSelectedCard(card_id, processor_type);
        setCardModalVisible(true);
    };

    const closeCardModal = () => {
        setSelectedCard(null);
        setCardModalVisible(false);
    };

    return (
        <div>
            {/* {hideSearch ? (
                <CollapsedSearchLayout
                    fetchMethod={fetchMethod}
                    columns={TransactionsColumns(openModal, openCardModal)}
                    filters={TransactionsFilters}
                    barRoles={[]}
                    hideSearchButton={hideSearch}
                    title={"Transactions"}
                />
            ) : (
                <CollapsedSearchLayout
                    fetchMethod={fetchMethod}
                    columns={TransactionsColumns(openModal, openCardModal)}
                    filters={TransactionsFilters}
                    barRoles={[]}
                    title={"Transactions"}
                />
            )} */}
            <CollapsedSearchLayout
                fetchMethod={fetchMethod}
                columns={TransactionsColumns(openModal, openCardModal)}
                filters={TransactionsFilters}
                barRoles={[]}
                title={"Transactions"}
                exportToExcel={(data) => exportToExcel(data, 'Transactions.xlsx')}
                exportData={transactionsData}
            />
            {selectedItem && (
                <TransactionDetailsModal
                    visible={modalVisible}
                    transaction={selectedItem}
                    closeModal={closeModal}
                />
            )}
            {selectedCard && (
                <CardDetailsModal
                    visible={cardModalVisible}
                    card={selectedCard}
                    closeModal={closeCardModal}
                />
            )}
        </div>

        // For the old details page uncomment this div, comment above div.

        // <div>
        //     {hideSearch ? (
        //         <CollapsedSearchLayout fetchMethod={fetchMethod} columns={TransactionsColumns} filters={TransactionsFilters} barRoles={[]} hideSearchButton={hideSearch} title={"Transactions"}/>
        //     ) : (
        //         <CollapsedSearchLayout fetchMethod={fetchMethod} columns={TransactionsColumns} filters={TransactionsFilters} barRoles={[]} title={"Transactions"}/>
        //     )}
        // </div>
    );
}

export default Transactions