import { useState } from 'react';
import { endpoint } from '../../AppGlobal';
import ClientColumns from './components/ClientColumns';
import ClientFilters from './components/ClientFilters';
import CollapsedSearchLayout from '../../components/CollapseSearchListView';
import Role from '../../const/role';
import { exportToExcel } from '../../const/exportToExcel';

function ClientManagement() {

    const [clientsData, setClientsData] = useState([]);

    const fetchMethod = async (page, filter) => {
        
        const response = await endpoint.fetchClients(page, filter);

        const updatedResults = response.results.map(item => ({
            ...item,
            master_account_balance: item.master_account_balance / 100,
            date_joined: new Date(item.date_joined).toLocaleDateString('en-GB'),
        }));
        setClientsData(updatedResults);
        return response;
    };

    return (
       
        <CollapsedSearchLayout 
        fetchMethod={fetchMethod} 
        columns={ClientColumns} 
        filters={ClientFilters} 
        barRoles = {[Role.ClientEditor, Role.ClientAdmin]}
        barAddURL = "/clients/create"
        title={"Clients"}
        exportToExcel={(data) => exportToExcel(data, 'Clients.xlsx')}
        exportData={clientsData}
        />
    );
}

export default ClientManagement;