// src/utils/exportUtils.js
import * as XLSX from 'xlsx';

export const exportToExcel = (data, filename) => {
    if (!data || data.length === 0) {
        console.error('No data available to export.');
        return;
    }

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, filename);
    XLSX.writeFile(workbook, filename);
};
