import React from 'react';
import CollapsedSearchLayout from '../../components/CollapseSearchListView'
import LogsColumns from './components/LogsColumns';
import LogsFilters from './components/LogsFilters';
import { endpoint } from '../../AppGlobal';


function Logs() {

    const fetchMethod = endpoint.getMethod('fetchLogs');
    return (
        <CollapsedSearchLayout columns={LogsColumns} fetchMethod={fetchMethod} filters={LogsFilters} barRoles={[]} title={"Logs"} exportButtonVisible={false} />
    )
}

export default Logs