import React from 'react'
import '../../../theme/css/Generic.css'
import { Col, Row, Card, Statistic, Layout, Spin, Avatar } from 'antd';
import { UserOutlined, TransactionOutlined, CreditCardOutlined, IdcardOutlined, BankOutlined, DollarOutlined} from '@ant-design/icons';
import DashboardIconSize from '../../../const/iconSizes';
import ProCard from '@ant-design/pro-card'

function DashboardHeader({ usage, loading }) {


    const activeClients = `${usage != null ? usage.total_clients : '-'}`;
    const total_processors = `${usage != null ? usage.total_processors : '-'}`;
    const transactionCount = `${usage != null ? usage.total_transactions : '-'}`;
    const totalAmount = `${usage != null ? usage.total_amount : '-'}`;
    const totalCards = `${usage != null ? usage.total_cards : '-'}`;
    const totalHolders = `${usage != null ? usage.total_holders : '-'}`;
    const { Meta } = Card;
    const { Content } = Layout;

    return (
        <Content>
            <Row
                align={'middle'}
                justify={'center'}
                 >
                <Col xs={24} sm={24} md={24} lg={12} xl={6} >
                    <div className='cardAlignment'>
                        <ProCard bordered={false}
                        >
                            <Meta
                                // style={{ margin: '10px' }}
                                avatar=
                                {
                                    <Avatar
                                        size={DashboardIconSize}
                                        icon={<UserOutlined />}
                                        style={{
                                            backgroundColor: '#1890ff',
                                            color: '#ffffff',
                                        }}
                                    />
                                }
                                title="Active Users"
                                description=
                                {
                                    loading ? (
                                        <Spin />
                                    ) : (
                                        <Statistic value={activeClients} />
                                    )
                                }
                            />
                        </ProCard>
                    </div>
                </Col>
                {/* <Col xs={24} sm={24} md={24} lg={12} xl={6} >
                    <div className='cardAlignment'>
                        <ProCard bordered={false}
                        >
                            <Meta
                                // style={{ margin: '10px' }}
                                avatar=
                                {
                                    <Avatar
                                        size={DashboardIconSize}
                                        icon={<BankOutlined />}
                                        style={{
                                            backgroundColor: 'rgb(24,220,255)',
                                            color: '#ffffff',
                                        }}
                                    />
                                }
                                title="Total Processors"
                                description=
                                {
                                    loading ? (
                                        <Spin />
                                    ) : (
                                        <Statistic value={total_processors} />
                                    )
                                }
                            />
                        </ProCard>
                    </div>
                </Col> */}
                <Col xs={24} sm={24} md={24} lg={12} xl={6} >
                    <div className='cardAlignment'>
                        <ProCard bordered={false}
                        >
                            <Meta
                                avatar=
                                {
                                    <Avatar
                                        size={DashboardIconSize}
                                        icon={<TransactionOutlined />}
                                        style={{
                                            backgroundColor: '#2980B9',
                                            color: '#ffffff',
                                        }}
                                    />
                                }
                                title="Total Transaction"
                                description=
                                {loading ? (
                                    <Spin />
                                ) : (
                                    <Statistic value={transactionCount} />
                                )}
                            />
                        </ProCard>
                    </div>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={6} >
                    <div className='cardAlignment'>
                        <ProCard bordered={false}
                        >
                            <Meta
                                avatar=
                                {
                                    <Avatar
                                        size={DashboardIconSize}
                                        icon={<DollarOutlined />}
                                        style={{
                                            backgroundColor: '#52c41a',
                                            color: '#ffffff',
                                        }}
                                    />
                                }
                                title="Total Amount"
                                description=
                                {loading ? (
                                    <Spin />
                                ) : (
                                    <Statistic value={totalAmount} />
                                )}
                            />
                        </ProCard>
                    </div>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={6} >
                    <div className='cardAlignment'>
                        <ProCard bordered={false}
                        >
                            <Meta
                                avatar=
                                {
                                    <Avatar
                                        size={DashboardIconSize}
                                        icon={<CreditCardOutlined />}
                                        style={{
                                            backgroundColor: '#722ed1',
                                            color: '#ffffff',
                                        }}
                                    />
                                }
                                title="Total Cards"
                                description=
                                {loading ? (
                                    <Spin />
                                ) : (
                                    <Statistic value={totalCards} />
                                )}
                            />
                        </ProCard>
                    </div>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={6} >
                    <div className='cardAlignment'>
                        <ProCard bordered={false}
                        >
                            <Meta
                                avatar=
                                {
                                    <Avatar
                                        size={DashboardIconSize}
                                        icon={<IdcardOutlined />}
                                        style={{
                                            backgroundColor: '#fa8c16',
                                            color: '#ffffff',
                                        }}
                                    />
                                }
                                title="Card Holders"
                                description=
                                {loading ? (
                                    <Spin />
                                ) : (
                                    <Statistic value={totalHolders} />
                                )}
                            />
                        </ProCard>
                    </div>
                </Col>
            </Row>
        </Content>
    )
}

export default DashboardHeader