import React from 'react'
import { endpoint } from '../../../AppGlobal';
import Role from '../../../const/role';
import CollapsedSearchLayout from '../../../components/CollapseSearchListView';
import ProgramsColumns from './components/ProgramsColumns';
import ProgramDetailsModal from './components/ProgramsDetailsModal';
import DrawerModal from '../../../components/DrawerModal';

function Program() {

    const fetchMethod = endpoint.getMethod('fetchPrograms');

    const { modalVisible, selectedItem, openModal, closeModal } = DrawerModal();

    return (
        <div>
            <CollapsedSearchLayout
                columns={ProgramsColumns(openModal)}  // remove openModal for the old details page
                fetchMethod={fetchMethod}
                filters={[]}
                barRoles={[Role.ProgramEditor, Role.ProgramAdmin]}
                searchRoles={[]}
                barAddURL="/programs/create"
                title={"Programs"}
                exportButtonVisible={false}
            />
            {selectedItem && (
                <ProgramDetailsModal
                    visible={modalVisible}
                    program={selectedItem}
                    closeModal={closeModal}
                />
            )}
        </div>

        // For the old details page uncomment this, comment above div.

        // <CollapsedSearchLayout
        // columns={ProgramsColumns}
        // fetchMethod={fetchMethod}
        // filters={[]}
        // barRoles={[Role.Superuser]}
        // barAddURL="/programs/create"
        // title={"Programs"}
        // />
    )

}

export default Program