import StatusIcon from "../../../components/StatusIcon";
import { IDType } from "../../../const/enums";
import countries from "../../../const/country.json"
import { ProcessorType } from "../../../const/enums";
export const HolderListFields = [

	{
		label: "First Name",
		dataIndex: 'first_name',

	},
	{
		label: "Middle Name",
		dataIndex: 'middle_name',
		sorter: () => { }
	},
	{
		label: "Last Name",
		dataIndex: 'last_name',
		sorter: () => { }
	},
	{
		label: "Date of Birth",
		dataIndex: 'date_of_birth',
	},
	{
		label: "Email",
		dataIndex: 'email',
		customRender: true,
		render: (text) => {
			return (
				<div>
					<div className='responsiveInline'>
						<a href={`mailto:${text}`}>
							{text}
						</a>
					</div>
				</div>
			)
		}
	},
	{
		label: "Created at",
		dataIndex: 'created_at',
		customRender: true,
		render: (value) => {
			const date = isNaN(new Date(value)) ? '-' : value.toListDateString()
			return (
				<div>
					<p className='responsiveInline'>
						{date}
					</p>
				</div>
			)
		},
		sorter: () => { }
	},
	{
		label: "Status",
		dataIndex: 'is_active',
		customRender: true,
		align: 'center',
		render: (queStatus) => {
			return (
				<StatusIcon status={queStatus} />
			)

		}
	},
	{
		label: "Nationality",
		dataIndex: 'country',
		align: 'center'
	},
	{
		label: "Phone",
		dataIndex: 'phone_number',
		customRender: true,
		render: (text) => {
			return (
				<div className='responsiveInline'>
					<a href={`tel:${text}`}>
						{text}
					</a>
				</div>
			)
		}
	},
	{
		label: "Id Type",
		dataIndex: 'id_type',
		customRender: true,
		render: (text) => {
			const value = IDType.ItemFromValue(text)
			return (
				<>{value}</>
			)
		}

	},
	{
		label: "Id Value",
		dataIndex: 'id_value',
	},
	{
		label: "Client",
		dataIndex: 'client',
	},
	{
		label: "Limit Group id",
		dataIndex: 'holder_limit_group_id',
		align: 'center'
	},
];

// const TribeHolderListFiels = [
// 	{
// 		label: "Limit Group id",
// 		dataIndex: 'holder_limit_group_id',
// 		align: 'center'
// 	},
// ]

export const AddressListFields = [


	{
		noLabel: true,
		dataIndex: 'address__sub__street_name',
		customRender: true,
		render: (text) => {
			return (
				<h4 style={{ display: 'inline', marginLeft: '-16px' }}>Address</h4>
			)
		}
	},
	{
		label: 'Street Name',
		dataIndex: 'address__sub__street_name'
	},
	{
		label: 'Street Number',
		dataIndex: 'address__sub__street_number'
	},
	{
		label: 'Floor',
		dataIndex: 'address__sub__floor'
	},
	{
		label: 'Apartment',
		dataIndex: 'address__sub__apartment'
	},
	{
		label: 'Zip Code',
		dataIndex: 'address__sub__zip_code'
	},
	{
		label: 'Neighborhood',
		dataIndex: 'address__sub__neighborhood'
	},
	{
		label: 'City',
		dataIndex: 'address__sub__city'
	},
	{
		label: 'Region',
		dataIndex: 'address__sub__region'
	},
	{
		label: 'Additional Info',
		dataIndex: 'address__sub__additional_info'
	},
	{
		label: 'Country Ison',
		dataIndex: 'address__sub__country_ison',
		type: 'Input',
		customRender: true,
		render: (text) => {
			let ison = -1
			try {
				ison = parseInt(text)
			} catch (e) {

			}
			const countryItem = countries.find(country => country.isoNumeric3 === parseInt(ison))
			const name = countryItem ? countryItem.countryName : 'Unknown';
			return (
				<>{name}</>
			)
		},
		rule: [
			{
				required: true,
			}
		]
	}


]


export const listFieldsForProcessor = (processorType) => {

	switch (processorType) {
		case ProcessorType.TRIBE.value:
			// let concat = HolderListFields.concat(TribeHolderListFiels).concat(AddressListFields)
			// return concat
			return HolderListFields.concat(AddressListFields)
		case ProcessorType.REAP.value:
			return HolderListFields.concat(AddressListFields)
		default:
			return HolderListFields.concat(AddressListFields)
	}


}