import { Status } from "../../../../const/enums";
const LimitGroupsFormFields = [
    {
        key: 0,
        title: 'Processor Type',
		placeholder: 'Processor Type',
        dataIndex: 'processor_type',
        type: 'Select',
		options: [
			{
				key: 1,
				label: 'Tribe',
				value: 1
			},
			{
				key: 2,
				label: 'Reap',
				value: 2
			}
		],
		rule:[
			{
				required: true,
				message: "Please select a processor type"
			},
		]
    },
    {
        key: 3,
        title: 'Limit Group ID',
		placeholder: 'Limit Group ID',
        dataIndex: 'limit_group_id',
		rule: [{
			required: true,
		}
		],
        type: 'Input'
    },
    {
        key: 4,
        title: 'Name',
		placeholder: 'Name',
        dataIndex: 'name',
		rule: [{
			required: true,
		}
		],
        type: 'Input'
    },
    {
        key: 5,
        title: 'Deleted',
		placeholder: 'Deleted',
        dataIndex: 'is_deleted',
		rule: [{
			required: true,
		}
		],
        type: 'Select',
		options: Status.asItemList(),
    },
    {
        key: 6,
        title: 'Active',
		placeholder: 'Active',
        dataIndex: 'is_active',
		rule: [{
			required: true,
		}
		],
        type: 'Select',
		options: Status.asItemList(),
    },
    
];

export default LimitGroupsFormFields