import { ProcessorType, AccountStatus } from '../../../const/enums';
import { isonToCountryName } from '../../../components/CountrySelector';
import StatusIcon from '../../../components/StatusIcon';

export const AccountListFields = [
    {
        label: 'Paccountid',
        dataIndex: 'paccountid',
    },
    {

        label: "Created at",
        dataIndex: 'created_at',
        customRender: true,
        render: (value) => {
            const date = isNaN(new Date(value)) ? '-' : value.toListDateString()
            return (
                <p className='responsiveInline'>
                    {date}
                </p>
            )
        },
    },
    {
        label: 'Country',
        dataIndex: 'currency_ison',
        customRender: true,
        render: (value, row) => {
            return (
                <p className='responsiveInline'>
                    {isonToCountryName(value)}
                </p>
            )
        }
    },
    {

        label: 'Account Status',
        dataIndex: 'status',
        customRender: true,
        render: (status) => {

            return (
                <p className='responsiveInline'>
                    {AccountStatus.ItemFromValue(status)}
                </p>
            )
        }

    },
    {

        label: "Is Deleted",
        dataIndex: 'is_deleted',
        customRender: true,
        align: 'center',
        render: (queStatus) => {
            return (
                <StatusIcon status={queStatus} />
            )

        }
    },
    {

        label: "Status",
        dataIndex: 'is_active',
        customRender: true,
        align: 'center',
        render: (queStatus) => {
            return (
                <StatusIcon status={queStatus} />
            )

        }
    },
    {
        label: 'Reserved Balance',
        dataIndex: 'formattedReservedBalance',
    },
    {
        label: 'Settled Balance',
        dataIndex: 'formattedSettledBalance',
    },
    {
        label: 'Available Balance',
        dataIndex: 'formattedAvailableBalance',
    },
    {
        label: 'Holder',
        dataIndex: 'holder',
        customRender: true,
        render: (value) => {
            const holder = value[0]
            return (
                <a href={`/holders/${holder.id}/detail`}>
                    {holder.first_name + ' ' + holder.last_name}
                </a>

            )
        }
    },
    {
        label: 'Client',
        dataIndex: 'client',
        customRender: true,
        render: (value) => {
            return (
                <div>
                    {value}
                </div>

            )
        }
    },
];

export const TribeAccountListFields = [


    {

        noLabel: true,
        dataIndex: 'extrafields__sub__iban_state',
        customRender: true,
        render: (text) => {
            return (
                <h4 style={{ display: 'inline', marginLeft: '-16px' }}>Additional Info</h4>
            )
        }
    },
    // {

    // 	label: 'Acs Password',
    // 	dataIndex: 'extrafields__sub__acs_password',
    // },
    {

        label: 'IBAN State',
        dataIndex: 'extrafields__sub__iban_state',
    },
    {

        label: 'Program ID',
        dataIndex: 'extrafields__sub__program_id',
    },
    {

        label: 'Fee Group ID',
        dataIndex: 'extrafields__sub__fee_group_id',
    },
    {

        label: 'Limit Group ID',
        dataIndex: 'extrafields__sub__limit_group_id',
    },
    {

        label: 'Status Change Note',
        dataIndex: 'extrafields__sub__status_change_note',
    },
    {

        label: 'Risk Rules Group ID',
        dataIndex: 'extrafields__sub__risk_rules_group_id',
    },
    {

        label: 'Skip Holder Validation',
        dataIndex: 'extrafields__sub__skip_holder_validation',
    },

]


export const listFieldsForAccount = (processorType) => {


    switch (processorType) {
        case ProcessorType.TRIBE.value:
            let concat = AccountListFields.concat(TribeAccountListFields)
            return concat
        case ProcessorType.REAP.value:
            return AccountListFields
        default:
            return AccountListFields
    }


}