
import { SessionManager } from "../utils/windowManager/SessionManager";
import { AuthInfo } from "../model/auth";
import { endpoint } from "../AppGlobal";


class Endpoint {
	static baseurl = process.env.REACT_APP_ENDPOINT;
	// static baseurl = "http://127.0.0.1:8000";
	authModel = null

	static methods = {
		//--Auth------------------------
		authanticate: loginItem => ({ url: '/api/admin/authanticate', method: 'POST', body: JSON.stringify(loginItem) }),
		refreshtoken: refreshItem => ({ url: '/api/admin/refresh', method: 'POST', body: JSON.stringify(refreshItem) }),
		logout: () => ({ url: '/api/admin/logout', method: 'GET', private: true }),
		//----------------------------------------------------------------
		//----DashBoard------------------------
		// listprocessors: () => ({ url: '/api/processors', method: 'GET', private: true }),
		usage: () => ({ url: '/api/admin/dashboard/usage', method: 'GET', private: true }),
		cardsTimeLine: () => ({ url: '/api/admin/dashboard/cardstimeline', method: 'GET', private: true }),
		lastnotifications: () => ({ url: '/api/admin/dashboard/notifications', method: 'GET', private: true }),
		//---Client------------------------
		listClients: (page, filter = {}) => ({ url: `/api/admin/clients?page=${page}${Endpoint.filterToQuery(filter)}`, method: 'GET', private: true }),
		exportClients: (filter = {}) => ({ url: `/api/admin/clients/export?${Endpoint.filterToQuery(filter)}`, method: 'GET', private: true }),
		detailClient: (clientId) => ({ url: `/api/admin/clients/${clientId}`, method: 'GET', private: true }),
		updateDetailClient: (clientId, clientData) => ({ url: `/api/admin/clients/${clientId}`, method: 'PUT', private: true, body: JSON.stringify(clientData) }),
		createClient: (clientData) => ({ url: `/api/admin/clients/create`, method: 'POST', private: true, body: JSON.stringify(clientData) }),
		listClientProcessors: (clientId, page = 1, filter = {}) => ({ url: `/api/admin/clients/${clientId}/processors?page=${page}${Endpoint.filterToQuery(filter)}`, method: 'GET', private: true }),
		fetchClientProcessor: processorId => ({ url: `/api/admin/clients/processor/${processorId}`, method: 'GET', private: true }),
		detailClientProcessor: (processorId) => ({ url: `/api/admin/clients/processor/${processorId}`, method: 'GET', private: true }),
		updateClientProcessor: (processorId, processorData) => ({ url: `/api/admin/clients/processor/${processorId}`, method: 'PUT', private: true, body: JSON.stringify(processorData) }),
		createClientProcessor: (processorData) => ({ url: `/api/admin/clients/processor/create`, method: 'POST', private: true, body: JSON.stringify(processorData) }),
		fetchPrograms: () => ({ url: `/api/admin/clients/options/programs`, method: 'GET', private: true }),
		fetchUsageGroups: () => ({ url: `/api/admin/clients/options/usagegroups`, method: 'GET', private: true }),
		fetchFeeGroups: () => ({ url: `/api/admin/clients/options/feegroups`, method: 'GET', private: true }),
		fetchLimitGroups: () => ({ url: `/api/admin/clients/options/limitgroups`, method: 'GET', private: true }),
		fetchGroups: () => ({ url: `/api/admin/clients/options/groups`, method: 'GET', private: true }),
		historyOfClient: (clientId, page = 1, filter = {}) => ({ url: `/api/admin/clients/${clientId}/history?page=${page}${Endpoint.filterToQuery(filter)}`, method: 'GET', private: true }),
		listClientsIP: (clientId, page = 1, filter = {}) => ({ url: `/api/admin/clients/${clientId}/ipaddresses?page=${page}${Endpoint.filterToQuery(filter)}`, method: 'GET', private: true }),
		createClientIP: (ipData) => ({ url: `/api/admin/clients/ipaddresses/create/${ipData.user}`, method: 'POST', private: true, body: JSON.stringify(ipData) }),
		deleteClientIP: (ipId) => ({ url: `/api/admin/clients/ipaddresses/delete/${ipId}`, method: 'DELETE', private: true }),
		//---------------------------------
		///HOLDER METHODS----------------------------------------------------------------
		listHolders: (page, filter = {}) => ({ url: `/api/admin/holders?page=${page}${Endpoint.filterToQuery(filter)}`, method: 'GET', private: true }),
		exportHolders: (filter = {}) => ({ url: `/api/admin/holders/export?${Endpoint.filterToQuery(filter)}`, method: 'GET', private: true }),
		detailHolder: (holderId) => ({ url: `/api/admin/holders/${holderId}`, method: 'GET', private: true }),
		// addressHolder: (holderId) => ({ url: `/api/admin/holders/${holderId}/address`, method: 'GET', private: true }),
		updateHolder: (holderId, holder) => ({ url: `/api/admin/holders/update/${holderId}`, method: 'PUT', private: true, body: JSON.stringify(holder) }),
		cardsOfHolder: (holderId, page = 1, filter = {}) => ({ url: `/api/admin/holders/${holderId}/cards?page=${page}${Endpoint.filterToQuery(filter)}`, method: 'GET', private: true }),
		accountsOfHolder: (holderId, page = 1, filter = {}) => ({ url: `/api/admin/holders/${holderId}/accounts?page=${page}${Endpoint.filterToQuery(filter)}`, method: 'GET', private: true }),
		balanceOfHolder: (holderId, page = 1, filter = {}) => ({ url: `/api/admin/holders/${holderId}/balance?page=${page}${Endpoint.filterToQuery(filter)}`, method: 'GET', private: true }),
		historyOfHolder: (holderId, page = 1, filter = {}) => ({ url: `/api/admin/holders/${holderId}/history?page=${page}${Endpoint.filterToQuery(filter)}`, method: 'GET', private: true }),
		commentsOfHolder: (holderId, page = 1, filter = {}) => ({ url: `/api/admin/holders/${holderId}/comments?page=${page}${Endpoint.filterToQuery(filter)}`, method: 'GET', private: true }),
		transactionsOfHolder: (holderId, page = 1, filter = {}) => ({ url: `/api/admin/holders/${holderId}/transactions?page=${page}${Endpoint.filterToQuery(filter)}`, method: 'GET', private: true }),
		transactionAuthorizationsOfHolder: (holderId, page = 1, filter = {}) => ({ url: `/api/admin/holders/${holderId}/transactions/authorizations?page=${page}${Endpoint.filterToQuery(filter)}`, method: 'GET', private: true }),
		transactionSettlementsOfHolder: (holderId, page = 1, filter = {}) => ({ url: `/api/admin/holders/${holderId}/transactions/settlements?page=${page}${Endpoint.filterToQuery(filter)}`, method: 'GET', private: true }),
		createComment: (holderId, holderData) => ({ url: `/api/admin/holders/${holderId}/comments`, method: 'POST', private: true, body: JSON.stringify(holderData) }),
		//----------------------------------------------------------------
		///TRANSACTIONS METHODS----------------------------------------------------------------
		listTransactions: (page, filter = {}) => ({ url: `/api/admin/transactions?page=${page}${Endpoint.filterToQuery(filter)}`, method: 'GET', private: true }),
		exportTransactions: (filter = {}) => ({ url: `/api/admin/transactions/export?${Endpoint.filterToQuery(filter)}`, method: 'GET', private: true }),
		listAuthorizations: (page, filter = {}) => ({ url: `/api/admin/transactions?page=${page}&request_type=cardAuthorization${Endpoint.filterToQuery(filter)}`, method: 'GET', private: true }),
		exportAuthorizations: (filter = {}) => ({ url: `/api/admin/transactions/export?request_type=cardAuthorization${Endpoint.filterToQuery(filter)}`, method: 'GET', private: true }),
		listSettlements: (page, filter = {}) => ({ url: `/api/admin/transactions?page=${page}&request_type=cardSettlement${Endpoint.filterToQuery(filter)}`, method: 'GET', private: true }),
		exportSettlements: (filter = {}) => ({ url: `/api/admin/transactions/export?request_type=cardSettlement${Endpoint.filterToQuery(filter)}`, method: 'GET', private: true }),
		detailTransactions: (transLink, req_type) => ({ url: `/api/admin/transactions/${req_type}/${transLink}`, method: 'GET', private: true }),
		//----------------------------------------------------------------
		///CARD METHODS----------------------------------------------------------------
		listCards: (page, filter = {}) => ({ url: `/api/admin/cards?page=${page}${Endpoint.filterToQuery(filter)}`, method: 'GET', private: true }),
		exportCards: (filter = {}) => ({ url: `/api/admin/cards/export?${Endpoint.filterToQuery(filter)}`, method: 'GET', private: true }),
		detailCards: (cardId) => ({ url: `/api/admin/cards/${cardId}`, method: 'GET', private: true }),
		updateCardStatus: (cardId, status) => ({ url: `/api/admin/cards/${cardId}/status`, method: 'PUT', private: true, body: JSON.stringify({ 'status': status, 'reason_code': 13 }) }),
		setCardPin: (cardId) => ({ url: `/api/admin/cards/${cardId}/setpin`, method: 'POST', private: true }),
		//----------------------------------------------------------------
		///USER METHODS----------------------------------------------------------------
		listUsers: (page, filter = {}) => ({ url: `/api/admin/users?page=${page}${Endpoint.filterToQuery(filter)}`, method: 'GET', private: true }),
		detailUsers: (userId) => ({ url: `/api/admin/users/${userId}`, method: 'GET', private: true }),
		updateDetailUser: (userId, userData) => ({ url: `/api/admin/users/${userId}`, method: 'PUT', private: true, body: JSON.stringify(userData) }),
		createUser: (userData) => ({ url: `/api/admin/users/create`, method: 'POST', private: true, body: JSON.stringify(userData) }),
		//----------------------------------------------------------------
		///ACCOUNTS METHODS----------------------------------------------------------------
		listAccounts: (page, filter = {}) => ({ url: `/api/admin/accounts?page=${page}${Endpoint.filterToQuery(filter)}`, method: 'GET', private: true }),
		exportAccounts: (filter = {}) => ({ url: `/api/admin/accounts/export?${Endpoint.filterToQuery(filter)}`, method: 'GET', private: true }),
		detailAccounts: (accountId) => ({ url: `/api/admin/accounts/${accountId}`, method: 'GET', private: true }),
		updateAccountStatus: (accountId, status) => ({ url: `/api/admin/accounts/${accountId}/status`, method: 'PUT', private: true, body: JSON.stringify({ 'status': status, 'reason_code': 13 }) }),
		//----------------------------------------------------------------
		///BALANCE METHODS----------------------------------------------------------------
		listBalance: (page, filter = {}) => ({ url: `/api/admin/balance?page=${page}${Endpoint.filterToQuery(filter)}`, method: 'GET', private: true }),
		exportBalance: (filter = {}) => ({ url: `/api/admin/balance/export?${Endpoint.filterToQuery(filter)}`, method: 'GET', private: true }),
		createBalance: (balanceData) => ({ url: `/api/admin/balance`, method: 'POST', private: true, body: JSON.stringify(balanceData) }),
		//----------------------------------------------------------------
		///PROCESSOR METHODS----------------------------------------------------------------
		listAllProcessors: (page, filter = {}) => ({ url: `/api/admin/processors?page=${page}${Endpoint.filterToQuery(filter)}`, method: 'GET', private: true }),
		//----------------------------------------------------------------
		///LOGS METHODS----------------------------------------------------------------
		listLogs: (page, filter = {}) => ({ url: `/api/admin/logs?page=${page}${Endpoint.filterToQuery(filter)}`, method: 'GET', private: true }),
		//----------------------------------------------------------------
		///PASSFORT SETTINGS METHODS----------------------------------------------------------------
		listPassfortSettings: () => ({ url: `/api/admin/settings`, method: 'GET', private: true }),
		detailPassfortSettings: () => ({ url: `/api/admin/settings`, method: 'GET', private: true }),
		updateDetailPassfortSettings: (settingsData) => ({ url: `/api/admin/settings/update`, method: 'PUT', private: true, body: JSON.stringify(settingsData) }),
		createPassfortSettings: (settingsData) => ({ url: `/api/admin/settings/create`, method: 'POST', private: true, body: JSON.stringify(settingsData) }),
		detailProgram: (programId) => ({ url: `/api/admin/settings/programs/${programId}`, method: 'GET', private: true }),
		updateDetailProgram: (programId, programData) => ({ url: `/api/admin/settings/programs/${programId}`, method: 'PUT', private: true, body: JSON.stringify(programData) }),
		createProgram: (programData) => ({ url: `/api/admin/settings/programs/create`, method: 'POST', private: true, body: JSON.stringify(programData) }),
		detailFeeGroups: (feegroupId) => ({ url: `/api/admin/settings/feegroups/${feegroupId}`, method: 'GET', private: true }),
		updateDetailFeeGroups: (feegroupId, feegroupData) => ({ url: `/api/admin/settings/feegroups/${feegroupId}`, method: 'PUT', private: true, body: JSON.stringify(feegroupData) }),
		createFeeGroups: (feegroupData) => ({ url: `/api/admin/settings/feegroups/create`, method: 'POST', private: true, body: JSON.stringify(feegroupData) }),
		detailLimitGroups: (limitgroupId) => ({ url: `/api/admin/settings/limitgroups/${limitgroupId}`, method: 'GET', private: true }),
		updateDetailLimitGroups: (limitgroupId, limitgroupData) => ({ url: `/api/admin/settings/limitgroups/${limitgroupId}`, method: 'PUT', private: true, body: JSON.stringify(limitgroupData) }),
		createLimitGroups: (limitgroupData) => ({ url: `/api/admin/settings/limitgroups/create`, method: 'POST', private: true, body: JSON.stringify(limitgroupData) }),
		detailUsageGroups: (usagegroupId) => ({ url: `/api/admin/settings/usagegroups/${usagegroupId}`, method: 'GET', private: true }),
		updateDetailUsageGroups: (usagegroupId, usagegroupData) => ({ url: `/api/admin/settings/usagegroups/${usagegroupId}`, method: 'PUT', private: true, body: JSON.stringify(usagegroupData) }),
		createUsageGroups: (usagegroupData) => ({ url: `/api/admin/settings/usagegroups/create`, method: 'POST', private: true, body: JSON.stringify(usagegroupData) }),
	}

	static status = {
		error: '',
		success: 'success'
	}

	constructor(sessionManager = new SessionManager()) {
		this.sessionManager = sessionManager;
	}

	static filterToQuery(filter) {
		let keys = Object.keys(filter);
		keys = keys.filter(key => filter[key] !== undefined && filter[key] !== null);
		return keys.map(key => `&${key}=${filter[key]}`).join('');

	}

	addAuthModel(access, refresh) {
		this.authModel = new AuthInfo(access, refresh);
		this.sessionManager.addAuthInfo(this.authModel)
	}

	async sendRequest(request, refresh = false) {
		const url = request.url;
		delete request.url;
		if (request.headers === undefined) {
			request.headers = {};
		}
		if (request.private !== undefined) {
			if (request.private && this.authModel != null) {
				request.headers["Authorization"] = `Bearer ${this.authModel.access}`;
				request.headers["X-PROCESSOR-ID"] = this.authModel.user.processor
			}
		}
		if (request.body !== undefined && request.headers['Content-Type'] === undefined) {
			request.headers['Content-Type'] = "application/json";
		}
		const response = await fetch(`${Endpoint.baseurl}${url}`, request);
		let responseObject = {}
		try {
			responseObject = await response.json()
		} catch (e) {
			console.log(e);
		};
		if (response.status >= 200 && response.status <= 299) {
			if (responseObject._status === undefined) {
				responseObject._status = Endpoint.status.success
				if (responseObject.access !== undefined && responseObject.refresh !== undefined) {
					this.addAuthModel(responseObject.access, responseObject.refresh)
				}
			}

		} else {
			console.log("-------->", responseObject)
			if (responseObject._status === undefined) {
				responseObject._status = Endpoint.status.error;
				if (responseObject.error !== undefined) {
					let currentError = responseObject.error;
					responseObject.message = currentError;
					delete responseObject.error;
				} else if (responseObject.detail !== undefined) {
					let currentError = responseObject.detail;
					responseObject.message = currentError;
					delete responseObject.detail;
				}
				else if (responseObject.message === undefined) {
					responseObject.message = '';
				}

			}
		}
		if (response.status === 403) {
			window.location.href = '/accessdenied';
			return;
		}
		if (response.status === 404) {
			window.location.href = '/notfound';
			return;
		}
		if (Endpoint.inrefresh && refresh) {
			Endpoint.inrefresh = false;
		}
		return responseObject;
	}
	async sendWithRefreshIfNeeded(request) {


		if (this.addAuthModel.isExpired) {

			const response = await Endpoint.sendRequest(Endpoint.methods.refreshtoken({ refresh: this.addAuthModel.refresh }), true);
			if (response._status === Endpoint.status.success) {
				const response = await this.sendRequest(request);
				return response
			} else {
				return response
			}
		} else {
			const response = await this.sendRequest(request);
			return response
		}
	}

	updateFilterForDateRange(filter, key = 'created_at') {
		let tempFilter = filter

		if (tempFilter['settle_date']) {
			tempFilter['settle_date'] = tempFilter['settle_date'].format('YYYY-MM-DD');
		}

		if (tempFilter['date_of_birth']) {
			tempFilter['date_of_birth'] = tempFilter['date_of_birth'].format('YYYY-MM-DD');
		}

		if (tempFilter['logs_date']) {
			const rangeBefore = filter[key][1] !== null ? filter[key][1].format('YYYY-MM-DD') : '';
			const rangeAfter = filter[key][0] !== null ? filter[key][0].format('YYYY-MM-DD') : '';
			delete tempFilter[key];
			tempFilter['start_date'] = rangeAfter;
			tempFilter['end_date'] = rangeBefore;
		}

		if (filter[key] !== undefined) {
			const created_at_range_before = filter[key][1] !== null ? filter[key][1].format('YYYY-MM-DD') : '';
			const created_at_range_after = filter[key][0] !== null ? filter[key][0].format('YYYY-MM-DD') : '';
			tempFilter = { ...filter }
			delete tempFilter[key]
			tempFilter[key + '_range_before'] = created_at_range_before
			tempFilter[key + '_range_after'] = created_at_range_after
		}
		return tempFilter
	}

	async login(loginItem) {

		Endpoint.inrefresh = true;
		const request = Endpoint.methods.authanticate(loginItem)
		const response = await this.sendRequest(request, true);
		return response;

	}

	async refreshtoken() {
		Endpoint.inrefresh = true;
		const request = Endpoint.methods.refreshtoken({ refresh: Endpoint.refreshToken });
		const response = await this.sendRequest(request, true);
		return response;

	}

	async logout() {
		const request = Endpoint.methods.logout()
		const response = await this.sendRequest(request)
		endpoint.authModel = null;
		return response
	}

	// async fetchProcessors() {
	// 	const request = Endpoint.methods.listprocessors();
	// 	const response = await this.sendWithRefreshIfNeeded(request);
	// 	return response['results'];
	// };


	async fetchUsage() {
		const request = Endpoint.methods.usage();
		const response = await this.sendWithRefreshIfNeeded(request)
		return response
	}

	async fetchCardsTimeLine() {

		const request = Endpoint.methods.cardsTimeLine();
		const response = await this.sendWithRefreshIfNeeded(request)
		return response

	}

	async fetchLastNotifications() {

		const request = Endpoint.methods.lastnotifications();
		const response = await this.sendWithRefreshIfNeeded(request);
		return response

	}

	// CLIENT

	async fetchClients(page, filter = {}) {
		let tempFilter = this.updateFilterForDateRange(filter)
		const request = Endpoint.methods.listClients(page, tempFilter);
		const response = await this.sendWithRefreshIfNeeded(request);
		return response

	}

	async fetchExportClients(filter = {}) {
		let tempFilter = this.updateFilterForDateRange(filter)

		const request = Endpoint.methods.exportClients(tempFilter);
		const response = await this.sendWithRefreshIfNeeded(request);
		return response

	}

	async fetchClientDetails(clientId) {
		const request = Endpoint.methods.detailClient(clientId);
		const response = await this.sendWithRefreshIfNeeded(request);
		return response;
	};

	async updateClientDetails(clientId, clientData) {
		const request = Endpoint.methods.updateDetailClient(clientId, clientData);
		const response = await this.sendWithRefreshIfNeeded(request);
		return response;
	}

	async createClient(clientData) {
		const request = Endpoint.methods.createClient(clientData);
		const response = await this.sendWithRefreshIfNeeded(request);
		return response;
	}

	async listClientProcessors(clientId, page, filter = {}) {
		const request = Endpoint.methods.listClientProcessors(clientId, page, filter);
		const response = await this.sendWithRefreshIfNeeded(request);
		return response;
	}

	async fetchClientProcessor(processorId) {
		const request = Endpoint.methods.fetchClientProcessor(processorId);
		const response = await this.sendWithRefreshIfNeeded(request);
		return response;
	}

	async updateClientProcessor(processorId, processorData) {
		const request = Endpoint.methods.updateClientProcessor(processorId, processorData);
		const response = await this.sendWithRefreshIfNeeded(request);
		return response;
	}

	async createClientProcessor(processorData) {
		const request = Endpoint.methods.createClientProcessor(processorData);
		const response = await this.sendWithRefreshIfNeeded(request);
		return response;
	}


	async fetchClientProcessorDetail(processorId) {
		const request = Endpoint.methods.detailClientProcessor(processorId);
		const response = await this.sendWithRefreshIfNeeded(request);
		return response;
	}

	async fetchPrograms() {
		const request = Endpoint.methods.fetchPrograms();
		const response = await this.sendWithRefreshIfNeeded(request);
		return response;
	}

	async fetchUsageGroups() {
		const request = Endpoint.methods.fetchUsageGroups();
		const response = await this.sendWithRefreshIfNeeded(request);
		return response;
	}

	async fetchFeeGroups() {
		const request = Endpoint.methods.fetchFeeGroups();
		const response = await this.sendWithRefreshIfNeeded(request);
		return response;
	}

	async fetchLimitGroups() {
		const request = Endpoint.methods.fetchLimitGroups();
		const response = await this.sendWithRefreshIfNeeded(request);
		return response;
	}

	async fetchGroups() {
		const request = Endpoint.methods.fetchGroups();
		const response = await this.sendWithRefreshIfNeeded(request);
		return response;
	}

	async fetchHistoryOfClient(holderId, page, filter = {}) {
		const request = Endpoint.methods.historyOfClient(holderId, page, filter);
		const response = await this.sendWithRefreshIfNeeded(request);
		return response;
	}

	async fetchClientsIP(page, filter = {}) {
		let tempFilter = this.updateFilterForDateRange(filter)
		const request = Endpoint.methods.listClientsIP(page, tempFilter);
		const response = await this.sendWithRefreshIfNeeded(request);
		return response

	}

	async createClientIP(ipData) {
		const request = Endpoint.methods.createClientIP(ipData);
		const response = await this.sendWithRefreshIfNeeded(request);
		return response;
	}

	async deleteClientIP(ipId) {
		const request = Endpoint.methods.deleteClientIP(ipId);
		const response = await this.sendWithRefreshIfNeeded(request);
		return response;
	};

	// HOLDER

	async fetchHolders(page, filter = {}) {
		let tempFilter = this.updateFilterForDateRange(filter)

		const request = Endpoint.methods.listHolders(page, tempFilter);
		const response = await this.sendWithRefreshIfNeeded(request);
		return response

	}

	async fetchExportHolders(filter = {}) {
		let tempFilter = this.updateFilterForDateRange(filter)

		const request = Endpoint.methods.exportHolders(tempFilter);
		const response = await this.sendWithRefreshIfNeeded(request);
		return response

	}

	async fetchHolderDetails(holderId) {
		const request = Endpoint.methods.detailHolder(holderId);
		const response = await this.sendWithRefreshIfNeeded(request);
		return response;
	};

	async updateHolder(holderId, holder) {

		const request = Endpoint.methods.updateHolder(holderId, holder);
		const response = await this.sendWithRefreshIfNeeded(request);
		return response;

	}

	// async fetchHolderAddress(holderId) {
	// 	const request = Endpoint.methods.addressHolder(holderId);
	// 	const response = await this.sendWithRefreshIfNeeded(request);
	// 	return response;
	// };

	async fetchCardsOfHolder(holderId, page, filter = {}) {
		const request = Endpoint.methods.cardsOfHolder(holderId, page, filter);
		const response = await this.sendWithRefreshIfNeeded(request);
		return response;
	}

	async fetchAccountsOfHolder(holderId, page, filter = {}) {
		const request = Endpoint.methods.accountsOfHolder(holderId, page, filter);
		const response = await this.sendWithRefreshIfNeeded(request);
		return response;
	}

	async fetchBalanceOfHolder(holderId, page, filter = {}) {
		const request = Endpoint.methods.balanceOfHolder(holderId, page, filter);
		const response = await this.sendWithRefreshIfNeeded(request);
		return response;
	}

	async fetchHistoryOfHolder(holderId, page, filter = {}) {
		const request = Endpoint.methods.historyOfHolder(holderId, page, filter);
		const response = await this.sendWithRefreshIfNeeded(request);
		return response;
	}

	async fetchCommentsOfHolder(holderId, page, filter = {}) {
		const request = Endpoint.methods.commentsOfHolder(holderId, page, filter);
		const response = await this.sendWithRefreshIfNeeded(request);
		return response;
	}

	async createCommentsOfHolder(holderId, holderData) {
		const request = Endpoint.methods.createComment(holderId, holderData);
		const response = await this.sendWithRefreshIfNeeded(request);
		return response;
	}

	async fetchTransactionsOfHolder(holderId, page, filter = {}) {
		const request = Endpoint.methods.transactionsOfHolder(holderId, page, filter);
		const response = await this.sendWithRefreshIfNeeded(request);
		return response;
	}

	async fetchTransactionAuthorizationsOfHolder(holderId, page, filter = {}) {
		const request = Endpoint.methods.transactionAuthorizationsOfHolder(holderId, page, filter);
		const response = await this.sendWithRefreshIfNeeded(request);
		return response;
	}

	async fetchTransactionSettlementsOfHolder(holderId, page, filter = {}) {
		const request = Endpoint.methods.transactionSettlementsOfHolder(holderId, page, filter);
		const response = await this.sendWithRefreshIfNeeded(request);
		return response;
	}

	// TRANSACTIONS
	async fetchTransactions(page, filter = {}) {
		let tempFilter = this.updateFilterForDateRange(filter, 'trx_date')
		// const currentPage = Object.keys(filter).length > 0 ? 1 : page;
		// const request = Endpoint.methods.listTransactions(currentPage, tempFilter);
		const request = Endpoint.methods.listTransactions(page, tempFilter);
		const response = await this.sendWithRefreshIfNeeded(request);
		return response

	}

	async fetchExportTransactions(filter = {}) {
		let tempFilter = this.updateFilterForDateRange(filter, 'trx_date')
		// const currentPage = Object.keys(filter).length > 0 ? 1 : page;
		// const request = Endpoint.methods.listTransactions(currentPage, tempFilter);
		const request = Endpoint.methods.exportTransactions(tempFilter);
		const response = await this.sendWithRefreshIfNeeded(request);
		return response

	}

	async fetchAuthorizations(page, filter = {}) {
		let tempFilter = this.updateFilterForDateRange(filter)
		const request = Endpoint.methods.listAuthorizations(page, tempFilter);
		const response = await this.sendWithRefreshIfNeeded(request);
		return response

	}

	async fetchExportAuthorizations(filter = {}) {
		let tempFilter = this.updateFilterForDateRange(filter, 'trx_date')
		const request = Endpoint.methods.exportAuthorizations(tempFilter);
		const response = await this.sendWithRefreshIfNeeded(request);
		return response

	}

	async fetchSettlements(page, filter = {}) {
		let tempFilter = this.updateFilterForDateRange(filter, 'trx_date')
		const request = Endpoint.methods.listSettlements(page, tempFilter);
		const response = await this.sendWithRefreshIfNeeded(request);
		return response

	}

	async fetchExportSettlements(filter = {}) {
		let tempFilter = this.updateFilterForDateRange(filter, 'trx_date')
		const request = Endpoint.methods.exportSettlements(tempFilter);
		const response = await this.sendWithRefreshIfNeeded(request);
		return response

	}

	async fetchTransactionsDetail(transLink, req_type) {
		const request = Endpoint.methods.detailTransactions(transLink, req_type);
		const response = await this.sendWithRefreshIfNeeded(request);
		return response;
	};

	// CARD
	async fetchCards(page, filter = {}) {
		let tempFilter = this.updateFilterForDateRange(filter)
		const request = Endpoint.methods.listCards(page, tempFilter);
		const response = await this.sendWithRefreshIfNeeded(request);
		return response

	}

	async fetchExportCards(filter = {}) {
		let tempFilter = this.updateFilterForDateRange(filter)

		const request = Endpoint.methods.exportCards(tempFilter);
		const response = await this.sendWithRefreshIfNeeded(request);
		return response

	}

	async fetchCardDetails(cardId) {
		const request = Endpoint.methods.detailCards(cardId);
		const response = await this.sendWithRefreshIfNeeded(request);
		return response;
	};

	async updateCardStatus(cardId, status) {
		const request = Endpoint.methods.updateCardStatus(cardId, status);
		const response = await this.sendWithRefreshIfNeeded(request);
		return response;
	}

	async setPin(cardId) {
		const request = Endpoint.methods.setCardPin(cardId);
		const response = await this.sendWithRefreshIfNeeded(request);
		return response;
	}

	// USER
	async fetchUsers(page, filter = {}) {
		let tempFilter = this.updateFilterForDateRange(filter, 'date_joined')
		const request = Endpoint.methods.listUsers(page, tempFilter);
		const response = await this.sendWithRefreshIfNeeded(request);
		return response

	}

	async fetchUserDetails(userId) {
		const request = Endpoint.methods.detailUsers(userId);
		const response = await this.sendWithRefreshIfNeeded(request);
		return response;
	};

	async updateUserDetails(userId, userData) {
		const request = Endpoint.methods.updateDetailUser(userId, userData);
		const response = await this.sendWithRefreshIfNeeded(request);
		return response;
	}

	async createUser(userData) {
		const request = Endpoint.methods.createUser(userData);
		const response = await this.sendWithRefreshIfNeeded(request);
		return response;
	}

	// ACCOUNT
	async fetchAccounts(page, filter = {}) {
		let tempFilter = this.updateFilterForDateRange(filter)
		const request = Endpoint.methods.listAccounts(page, tempFilter);
		const response = await this.sendWithRefreshIfNeeded(request);
		return response
	}

	async fetchExportAccounts(filter = {}) {
		let tempFilter = this.updateFilterForDateRange(filter)

		const request = Endpoint.methods.exportAccounts(tempFilter);
		const response = await this.sendWithRefreshIfNeeded(request);
		return response

	}

	async fetchAccountDetails(accountId) {
		const request = Endpoint.methods.detailAccounts(accountId);
		const response = await this.sendWithRefreshIfNeeded(request);
		return response;
	};

	async updateAccountStatus(accountId, status) {
		const request = Endpoint.methods.updateAccountStatus(accountId, status);
		const response = await this.sendWithRefreshIfNeeded(request);
		return response;
	}

	// BALANCE

	async fetchBalance(page, filter = {}) {
		let tempFilter = this.updateFilterForDateRange(filter)
		const request = Endpoint.methods.listBalance(page, tempFilter);
		const response = await this.sendWithRefreshIfNeeded(request);
		return response

	}

	async fetchExportBalance(filter = {}) {
		let tempFilter = this.updateFilterForDateRange(filter)

		const request = Endpoint.methods.exportBalance(tempFilter);
		const response = await this.sendWithRefreshIfNeeded(request);
		return response

	}

	async createBalance(balanceData) {
		const request = Endpoint.methods.createBalance(balanceData);
		const response = await this.sendWithRefreshIfNeeded(request);
		return response;
	}

	// PROCESSOR

	async fetchAllProcessors(page, filter = {}) {
		let tempFilter = this.updateFilterForDateRange(filter)
		const request = Endpoint.methods.listAllProcessors(page, tempFilter);
		const response = await this.sendWithRefreshIfNeeded(request);
		return response

	}

	// LOGS

	async fetchLogs(page, filter = {}) {
		let tempFilter = this.updateFilterForDateRange(filter, 'logs_date')
		const request = Endpoint.methods.listLogs(page, tempFilter);
		const response = await this.sendWithRefreshIfNeeded(request);
		return response

	}

	// SETTINGS

	async fetchPassfortSettings() {
		const request = Endpoint.methods.listPassfortSettings();
		const response = await this.sendWithRefreshIfNeeded(request);
		return response

	}

	async fetchPassfortSettingsDetails() {
		const request = Endpoint.methods.detailPassfortSettings();
		const response = await this.sendWithRefreshIfNeeded(request);
		return response;
	};

	async updatePassfortSettingsDetails(id/*  id is allways undefined, because api doesn't need id.   Passfort settings can be only only one  */, settingsData) {
		const request = Endpoint.methods.updateDetailPassfortSettings(settingsData);
		const response = await this.sendWithRefreshIfNeeded(request);
		return response;
	}

	async createPassfortSettings(settingsData) {
		const request = Endpoint.methods.createPassfortSettings(settingsData);
		const response = await this.sendWithRefreshIfNeeded(request);
		return response;
	}

	async fetchProgramDetail(programId) {
		const request = Endpoint.methods.detailProgram(programId);
		const response = await this.sendWithRefreshIfNeeded(request);
		return response;
	}

	async updateProgramDetails(programId, programData) {
		const request = Endpoint.methods.updateDetailProgram(programId, programData);
		const response = await this.sendWithRefreshIfNeeded(request);
		return response;
	}

	async createProgram(programData) {
		const request = Endpoint.methods.createProgram(programData);
		const response = await this.sendWithRefreshIfNeeded(request);
		return response;
	}

	async fetchFeeGroupsDetail(feegroupId) {
		const request = Endpoint.methods.detailFeeGroups(feegroupId);
		const response = await this.sendWithRefreshIfNeeded(request);
		return response;
	}

	async updateFeeGroupsDetails(feegroupId, feegroupData) {
		const request = Endpoint.methods.updateDetailFeeGroups(feegroupId, feegroupData);
		const response = await this.sendWithRefreshIfNeeded(request);
		return response;
	}

	async createFeeGroups(feegroupData) {
		const request = Endpoint.methods.createFeeGroups(feegroupData);
		const response = await this.sendWithRefreshIfNeeded(request);
		return response;
	}

	async fetchLimitGroupsDetail(limitgroupId) {
		const request = Endpoint.methods.detailLimitGroups(limitgroupId);
		const response = await this.sendWithRefreshIfNeeded(request);
		return response;
	}

	async updateLimitGroupsDetails(limitgroupId, limitgroupData) {
		const request = Endpoint.methods.updateDetailLimitGroups(limitgroupId, limitgroupData);
		const response = await this.sendWithRefreshIfNeeded(request);
		return response;
	}

	async createLimitGroups(limitgroupData) {
		const request = Endpoint.methods.createLimitGroups(limitgroupData);
		const response = await this.sendWithRefreshIfNeeded(request);
		return response;
	}

	async fetchUsageGroupsDetail(usagegroupId) {
		const request = Endpoint.methods.detailUsageGroups(usagegroupId);
		const response = await this.sendWithRefreshIfNeeded(request);
		return response;
	}

	async updateUsageGroupsDetails(usagegroupId, usagegroupData) {
		const request = Endpoint.methods.updateDetailUsageGroups(usagegroupId, usagegroupData);
		const response = await this.sendWithRefreshIfNeeded(request);
		return response;
	}

	async createUsageGroups(usagegroupData) {
		const request = Endpoint.methods.createUsageGroups(usagegroupData);
		const response = await this.sendWithRefreshIfNeeded(request);
		return response;
	}

	getMethod(method) {
		return this[method].bind(this)
	}


}

export default Endpoint;