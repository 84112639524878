export const IDType = {
	PASSPORT: { value: 'P', label: 'Passport', key: 'P' },
	DRIVER_LICENSE: { value: 'D', label: 'Driver License', key: 'D' },
	NATIONAL_ID: { value: 'N', label: 'National Id', key: 'N' },
	OTHER: { value: 'O', label: 'Other', key: 'O' },
	asItemList: () => [IDType.PASSPORT, IDType.DRIVER_LICENSE, IDType.NATIONAL_ID, IDType.OTHER]

}

export const ProcessorType = {

	TRIBE: { value: 1, label: 'Tribe' },
	REAP: { value: 2, label: 'Reap' },

}

export const SchemeType = {

	VISA: { value: 4, label: 'Visa' },
	MASTERCARD: { value: 5, label: 'Mastercard' },
	UPI: { value: 9, label: 'UPI' },

}

export const ContactType = {

	NONCONTACTLESS: { value: 0, label: 'Non contactless' },
	CONTACTLESS: { value: 1, label: 'Contactless' },

}

export const Programs = {

	Program80: { value: 80, label: 'Jeton UPI Virtual' },
	Program2701141: { value: 2701141, label: 'FH UPI Consumer Virtual' },
	Program2701181: { value: 2701181, label: 'Jeton UPI Physical' },
	Program2701272: { value: 2701272, label: 'FH UPI Business Physical UK' },
	Program2701451: { value: 2701451, label: 'FH UPI Consumer Physical UK Test' },
	Program27014650: { value: 27014650, label: 'FH UPI Consumer Physical UK Test' },
	Program1471458315: { value: 1471458315, label: 'Consumer Jeton Physical Prepaid' },
	Program1471458316: { value: 1471458316, label: 'Consumer Jeton Virtual Prepaid' },
	Program1471458346: { value: 1471458346, label: 'Jeton MC Physical' },
	Program1471458347: { value: 1471458347, label: 'Jeton MC Virtual' },
	Program14714585346: { value: 14714585346, label: 'Consumer FH Physical Prepaid' },
	Program14714585347: { value: 14714585347, label: 'Consumer FH Virtual Prepaid' },
	Program14714585368: { value: 14714585368, label: 'M M UPI Consumer Physical Uk' },
	Program14714585369: { value: 14714585369, label: 'FH UPI M M Virtual UK' },

}

export const RequestTypes = {
	cardAuthorization: { value: 'cardAuthorization', label: 'Card Authorization' },
	cardSettlement: { value: 'cardSettlement', label: 'Card Settlement' },
	balanceAdjustment: { value: 'balanceAdjustment', label: 'Balance Adjustment' },
	directCreditReceived: { value: 'directCreditReceived', label: 'Direct Credit Recieved' },
	cardAuthorizationExpired: { value: 'cardAuthorizationExpired', label: 'Card Authorization Expired' },
	externalPaymentSettlement: { value: 'externalPaymentSettlement', label: 'External Payment Settlement' },
}


export const Titles = {
	MR: { value: 'Mr', label: 'Mr' },
	MRS: { value: 'Mrs', label: 'Mrs' },
	DR: { value: 'Dr', label: 'Dr' },
}

export const UsageGroupValues = {
	ENABLE: { value: "E", label: 'Enable' },
	DISABLE: { value: "D", label: 'Disable' }
}

export const KYCType = {
	CLIENT: { value: -1, label: 'Client' },
	PASSFORT: { value: 1, label: 'Passfort' }
}

export const MonitoringType = {
	UNMONITORED: { value: -1, label: 'Unmonitored' },
	Napier: { value: 1, label: 'Napier' }
}

export const ErrorType = {
	ERROR403: { value: "403", label: 'Sorry, you are not authorized to access this page.' },
	ERROR404: { value: "404", label: 'Sorry, the page you visited does not exist.' },
}

export const OTPType = {
	CLIENT3D: { value: 2, label: 'Client - 3D OTP' },
	FH3D: { value: 1, label: 'FH - 3D OTP' }
}

export const KYCStatus = {
	WAITING: { value: 'WAITING', label: 'waiting' },
	APPLIED: { value: 'APPLIED', label: 'applied' },
	APPROVED: { value: 'APPROVED', label: 'approved' },
	IN_REVIEW: { value: 'IN_REVIEW', label: 'in_review' },
	REJECTED: { value: 'REJECTED', label: 'rejected' },
	CANCELLED: { value: 'CANCELLED', label: 'cancelled' },
}

export const CardStatus = {
	ACTIVATED: { value: 'A', label: 'Activated' },
	BLOCKED: { value: 'B', label: 'Blocked' },
	SUSPENDED: { value: 'T', label: 'Suspended' },
	RISK: { value: 'R', label: 'Risk' },
	STOLEN: { value: 'S', label: 'Stolen' },
	LOST: { value: 'L', label: 'Lost' },
	EXPIRED: { value: 'E', label: 'Expired' },
	NOTACTIVATED: { value: 'N', label: 'Not Activated' },
	FRAUD: { value: 'F', label: 'Fraud' }
}

export const AccountStatus = {
	ACTIVE: { value: 'A', label: 'Active' },
	RECEIVEONLY: { value: 'R', label: 'Receive only' },
	SPENDONLY: { value: 'P', label: 'Spend only' },
	SUSPENDED: { value: 'S', label: 'Suspended' },
	BLOCKED: { value: 'B', label: 'Blocked' }
}

export const StatusChangeReasonCode = {
	POSITIVE: { value: 1, label: 'Positive review' },
	NEGATIVE: { value: 2, label: 'Negative review' },
	CUSTOMERREQUEST: { value: 3, label: 'Customer request' },
	DEATHACCOUNTHOLDER: { value: 4, label: 'Death of an account holder' },
	SUSPECTEDFRAUD: { value: 5, label: 'Risk check - suspected fraud' },
	COMPROMISEDACCOUNT: { value: 6, label: 'Risk check - compromised account' },
	COMPROMISEDCARD: { value: 7, label: 'Risk check - compromised card' },
	INVESTIGATION: { value: 8, label: 'Risk check - investigation' },
	INDEMNITY: { value: 9, label: 'Indemnity received' },
	THIRDPARTY: { value: 10, label: 'Third party notification' },
	POLICEREQUEST: { value: 11, label: 'Police request' },
	INTERNALBLOCK: { value: 12, label: 'Internal block request' },
	SUSPECTEDIDENTITY: { value: 13, label: 'Suspected identity theft' },
	CHARGEBACK: { value: 14, label: 'Charge back fraud' },
	INTERNALINVESTIGATION: { value: 15, label: 'Internal investigations' },
	LOST: { value: 16, label: 'Lost or stolen cards' },
	SUSPICIOUS: { value: 17, label: 'Suspicious payments' },
	SUSPECTEDMONEY: { value: 18, label: 'Suspected money laundering concerns' },
	UNKNOWN: { value: 19, label: 'Unknown source of funds' },
}

export const TransactionStatus = {
	AUTHORIZED: { value: 'A', label: 'Authorized' },
	FAILED: { value: 'I', label: 'Failed' },
	SETTLED: { value: 'S', label: 'Settled' },
}


export const Status = {
	Active: {
		key: 0,
		label: 'Active',
		value: true
	},
	NotActive: {
		key: 1,
		label: 'Not Active',
		value: false
	}
}

export const LoadType = {
	INTERNAL: { value: 1, label: 'Internal Credit' },
	DEBIT: { value: 2, label: 'API Debit Adjustment' },
	CREDIT: { value: 3, label: 'API Credit Adjustment ' },
	LOAD: { value: 4, label: 'API Load' },
	INTERNALDEBIT: { value: 5, label: 'Internal Debit' }

}

export const TransactionType = {
	VALUE_0: { value: 0, label: 'Unknown' },
	VALUE_1: { value: 1, label: 'Load' },
	VALUE_2: { value: 2, label: 'Pos' },
	VALUE_3: { value: 3, label: 'Atm' },
	VALUE_4: { value: 4, label: 'Unload' },
	VALUE_5: { value: 5, label: 'Credit cheque' },
	VALUE_6: { value: 6, label: 'Balance inquiry' },
	VALUE_7: { value: 7, label: 'Cashback' },
	VALUE_8: { value: 8, label: 'Cash' },
	VALUE_9: { value: 9, label: 'Quasi cash' },
	VALUE_10: { value: 10, label: 'Credit' },
	VALUE_11: { value: 11, label: 'Credit adjustment' },
	VALUE_12: { value: 12, label: 'Refund' },
	VALUE_13: { value: 13, label: 'Debit adjustment' },
	VALUE_14: { value: 14, label: 'Pin unblock' },
	VALUE_15: { value: 15, label: 'Pin change' },
	VALUE_16: { value: 16, label: 'Reserved' },
	VALUE_17: { value: 17, label: 'Pos verification only' },
	VALUE_18: { value: 18, label: 'Reserved' },
	VALUE_19: { value: 19, label: 'Reserved' },
	VALUE_20: { value: 20, label: 'Money transfer' },
	VALUE_21: { value: 21, label: 'P2p debit' },
	VALUE_22: { value: 22, label: 'P2p credit' },
	VALUE_23: { value: 23, label: 'Debit cheque' },
	VALUE_24: { value: 24, label: 'Card activate' },
	VALUE_25: { value: 25, label: 'Pin change API' },
	VALUE_26: { value: 26, label: 'P2p Debit account to account' },
	VALUE_27: { value: 27, label: 'P2p Credit account to account' },
	VALUE_28: { value: 28, label: 'P2p Debit person to person' },
	VALUE_29: { value: 29, label: 'P2p Credit person to person' },
	VALUE_30: { value: 30, label: 'P2p debit financial institution' },
	VALUE_31: { value: 31, label: 'P2p credit financial institution' },
	VALUE_32: { value: 32, label: 'P2p debit prepaid card load and top up' },
	VALUE_33: { value: 33, label: 'P2p credit prepaid card load and top up' },
	VALUE_34: { value: 34, label: 'P2p debit wallet transfer' },
	VALUE_35: { value: 35, label: 'P2p credit wallet transfer' },
	VALUE_36: { value: 36, label: 'P2p debit card bill pay' },
	VALUE_37: { value: 37, label: 'P2p credit card bill pay' },
	VALUE_38: { value: 38, label: 'P2p debit non card bill pay' },
	VALUE_39: { value: 39, label: 'P2p credit non card bill pay' },
	VALUE_40: { value: 40, label: 'P2p debit non online gambling/gaming' },
	VALUE_41: { value: 41, label: 'P2p credit non online gambling/gaming' },
	VALUE_42: { value: 42, label: 'P2p debit online gambling/gaming' },
	VALUE_43: { value: 43, label: 'P2p credit online gambling/gaming' },
	VALUE_44: { value: 44, label: 'P2p debit government disbursement and tax refund' },
	VALUE_45: { value: 45, label: 'P2p credit government disbursement and tax refund' },
	VALUE_46: { value: 46, label: 'P2p debit loyalty payments' },
	VALUE_47: { value: 47, label: 'P2p credit loyalty payments' },
	VALUE_48: { value: 48, label: 'P2p debit merchant settlement' },
	VALUE_49: { value: 49, label: 'P2p credit merchant settlement' },
	VALUE_50: { value: 50, label: 'P2p debit payroll and pensions' },
	VALUE_51: { value: 51, label: 'P2p credit payroll and pensions' },
	VALUE_52: { value: 52, label: 'P2p debit b2b supplier payments' },
	VALUE_53: { value: 53, label: 'P2p credit b2b supplier payments' },
	VALUE_54: { value: 54, label: 'P2p debit other disbursements' },
	VALUE_55: { value: 55, label: 'P2p credit other disbursements' },
	VALUE_56: { value: 56, label: 'Outbound from external payment address' },
	VALUE_57: { value: 57, label: 'Inbound from external payment address' },
	VALUE_58: { value: 58, label: 'Direct debit from external payment address' },
	VALUE_59: { value: 59, label: 'Direct credit from external payment address' },
	VALUE_60: { value: 60, label: 'P2p credit agent cash out' },
	VALUE_61: { value: 61, label: 'P2p debit agent cash out' },
	VALUE_62: { value: 62, label: 'P2p credit merchant presented qr' },
	VALUE_63: { value: 63, label: 'P2p debit merchant presented qr' },
	VALUE_64: { value: 64, label: 'Pin unblock API' },
	VALUE_65: { value: 65, label: 'Inbound return from external payment' },
	VALUE_66: { value: 66, label: 'Outbound return from external payment' },
	VALUE_67: { value: 67, label: 'Direct credit return' },
	VALUE_68: { value: 68, label: 'Direct debit return' },
	VALUE_69: { value: 69, label: 'Custom fee' },
	VALUE_70: { value: 70, label: 'P2P credit cash deposit' },
	VALUE_71: { value: 71, label: 'P2P debit cash deposit' },
	VALUE_72: { value: 72, label: 'P2P debit merchant payment' },
	VALUE_73: { value: 73, label: 'P2P credit merchant payment' },
	VALUE_74: { value: 74, label: 'First chargeback' },
	VALUE_75: { value: 75, label: 'Second presentment reversal' },
	VALUE_76: { value: 76, label: 'Retrieval request' },
	VALUE_77: { value: 77, label: 'P2P credit funds transfer' },
	VALUE_78: { value: 78, label: 'P2P debit funds transfer' },
	VALUE_79: { value: 79, label: 'Dispute credit adjustment' },
	VALUE_80: { value: 80, label: 'Dispute debit adjustment' },
	VALUE_81: { value: 81, label: 'Second presentment' },
	VALUE_82: { value: 82, label: 'Inbound return reversal from external payment' },
}


SchemeType.asItemList = UsageGroupValues.asItemList = TransactionStatus.asItemList = ContactType.asItemList = RequestTypes.asItemList = Programs.asItemList = TransactionType.asItemList = LoadType.asItemList = StatusChangeReasonCode.asItemList = AccountStatus.asItemList = Status.asItemList = CardStatus.asItemList = KYCStatus.asItemList = KYCType.asItemList = ProcessorType.asItemList = IDType.asItemList = MonitoringType.asItemList = OTPType.asItemList = Titles.asItemList = function () {
	const list = Object.keys(this).map(key => this[key]).map(value => { return { ...value, key: value['value'] } }).filter(item => item.key !== undefined);
	return list
}
SchemeType.EnumItemFromValue = UsageGroupValues.EnumItemFromValue = TransactionStatus.EnumItemFromValue = ContactType.EnumItemFromValue = RequestTypes.EnumItemFromValue = Programs.EnumItemFromValue = TransactionType.EnumItemFromValue = LoadType.EnumItemFromValue = StatusChangeReasonCode.EnumItemFromValue = AccountStatus.EnumItemFromValue = Status.EnumItemFromValue = CardStatus.EnumItemFromValue = KYCStatus.EnumItemFromValue = KYCType.EnumItemFromValue = ProcessorType.EnumItemFromValue = IDType.EnumItemFromValue = MonitoringType.EnumItemFromValue = OTPType.EnumItemFromValue = Titles.EnumItemFromValue = function (value) {
	let items = this.asItemList().filter(item => item.value === value)
	return items.length > 0 ? items[items.length - 1] : null;
}

SchemeType.ItemFromValue = UsageGroupValues.ItemFromValue = TransactionStatus.ItemFromValue = ContactType.ItemFromValue = RequestTypes.ItemFromValue = Programs.ItemFromValue = TransactionType.ItemFromValue = LoadType.ItemFromValue = StatusChangeReasonCode.ItemFromValue = AccountStatus.ItemFromValue = Status.ItemFromValue = CardStatus.ItemFromValue = KYCStatus.ItemFromValue = KYCType.ItemFromValue = ProcessorType.ItemFromValue = IDType.ItemFromValue = MonitoringType.ItemFromValue = OTPType.ItemFromValue = Titles.ItemFromValue = function (value) {
	let items = this.EnumItemFromValue(value)
	return items ? items.label : null
}