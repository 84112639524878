import React, {useState} from 'react';
import { Layout, Pagination, Button } from 'antd';
import { FileExcelOutlined } from "@ant-design/icons"
import ProTable from '@ant-design/pro-table'
import { ConfigProvider } from 'antd';
import enUS from 'antd/lib/locale/en_US';
import { useDispatch } from "react-redux";
import { createNotification } from "../stores/reducers/notificationreducer";

function GenericTable({ dataSource, loading, columns, pageSize = 0, currentPage, pageHandler = null, onChangeHandler = () => { }, on, title, exportToExcel, exportData, exportButtonVisible = true }) {

  const { Content } = Layout;
  const pagination = pageSize === 0 ? false : { pageSize: pageSize }
  const total = dataSource.count || 0
  const [isExporting, setIsExporting] = useState(false);
  const dispatch = useDispatch();
  const responsiveTitles = (columns) => {
    columns.map((item, index) => {
      item.key = 'columns_' + item.dataIndex + index
      if (item.customRender === undefined || item.customRender === false) {
        item.render = (title) => {
          return (
            <div>
              <div className='responsiveTableData'>
                {item.title}:
              </div>
              <div style={{ display: 'inline' }}>
                {title}
              </div>
            </div>
          )
        }

      }
      return item

    })

    return columns
  }

  const handleExportClick = async () => {
    setIsExporting(true); // Start loading

    try {
      await exportToExcel(exportData); // Export işlemini bekle
      dispatch(createNotification({ 
        title: 'Success', 
        message: 'Export completed successfully.', 
        type: 'success' 
      }));
    } catch (error) {
      console.error('Export failed:', error); // Hata durumunu console'a yaz

      // Error notification gönder
      dispatch(createNotification({ 
        title: 'Error', 
        message: 'An error occurred during export.', 
        type: 'error' 
      }));
    } finally {
      setIsExporting(false); // Stop loading
    }
  };

  return (
    <ConfigProvider locale={enUS}>
      <Content className='main-container'
      >
        <div className='tableContainer'>
          <h3>{title}</h3>
          <div style={{ marginBottom: '20px', textAlign:'right' }}>
            {exportButtonVisible && (
              <Button onClick={handleExportClick} type="primary" htmlType="submit" icon={<FileExcelOutlined loading={isExporting} />}>Export</Button>
            )}
          </div>
          <ProTable
            bordered
            columns={responsiveTitles(columns)}
            dataSource={dataSource.results}
            loading={loading}
            rowKey={(record) => record.id}
            onChange={onChangeHandler}
            pagination={false}
            search={false}
            // toolBarRender={false}
            // options={false}
            defaultSize='small' // means compact
            options={{ reload: false }}
            // options={{density:false, reload:false}}
            scroll={{ x: 'max-content' }}
          />
        </div>
        {pageSize > 0 && !loading ? (<Pagination onChange={pageHandler != null ? pageHandler : () => { }} style={{ float: 'left', padding: '20px' }} defaultPageSize={pageSize} defaultCurrent={currentPage} total={total} showSizeChanger={false} />) : (<></>)}
      </Content>
    </ConfigProvider>
  );
}

export default GenericTable;
