import { ContactType, Status } from "../../../../const/enums";
const ProgramsFormFields = [
    {
        key: 0,
        title: 'Processor Type',
		placeholder: 'Processor Type',
        dataIndex: 'processor_type',
        type: 'Select',
		options: [
			{
				key: 1,
				label: 'Tribe',
				value: 1
			},
			{
				key: 2,
				label: 'Reap',
				value: 2
			}
		],
		rule:[
			{
				required: true,
				message: "Please select a processor type"
			},
		]
    },
    {
        key: 3,
        title: 'Program ID',
		placeholder: 'Program ID',
        dataIndex: 'program_id',
		rule: [{
			required: true,
		}
		],
        type: 'Input'
    },
    {
        key: 4,
        title: 'Name',
		placeholder: 'Name',
        dataIndex: 'name',
		rule: [{
			required: true,
		}
		],
        type: 'Input'
    },
    {
        key: 5,
        title: 'Default Currency',
		placeholder: 'Default Currency',
        dataIndex: 'default_currency',
		type: 'Currency',
		rule:[
			{
				required: true,
				message: "Please select a currency type"
			},
		]
    },
    {
        key: 6,
        title: 'Contact Type',
		placeholder: 'Contact Type',
        dataIndex: 'contact_type',
		rule: [{
			required: true,
		}
		],
        type: 'Select',
        options: ContactType.asItemList(),
    },
    {
        key: 7,
        title: 'Virtual',
		placeholder: 'Virtual',
        dataIndex: 'is_virtual',
		rule: [{
			required: true,
		}
		],
        type: 'Select',
		options: Status.asItemList(),
    },
    {
        key: 8,
        title: 'Enabled',
		placeholder: 'Enabled',
        dataIndex: 'is_enabled',
		rule: [{
			required: true,
		}
		],
        type: 'Select',
		options: Status.asItemList(),
    },
    
];

export default ProgramsFormFields